import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import Img from "gatsby-image";

const ProjectsList = styled.div`


// border-bottom: 1px solid ${styles.colors.quiet.light};
margin: 0 auto;
padding-bottom: 0;

& div.nirovision {
  background: ${styles.colors.nirovision.base};
}
& div.cammy {
  background: ${styles.colors.cammy.base};
}
& div.race {
  background: ${styles.colors.race.base};
}
& div.eurovision {
  background: ${styles.colors.eurovision.base};
}
& div.universal {
  background: ${styles.colors.universal.base};
}
& div.paramount {
  background: ${styles.colors.paramount.base};
}

@media ${styles.breakpoints.tabletPortrait} {
  margin: 0 auto;
}
@media ${styles.breakpoints.mobile} {
  margin: 0 auto;
}
`;

const Container = styled.div`
padding-right: ${styles.spaces.medium};
padding-left: ${styles.spaces.medium};
padding-top: ${styles.spaces.medium};
padding-bottom: ${styles.spaces.xLarge};
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  overflow: hidden;
  }
  @media ${styles.breakpoints.tabletPortrait} {
    flex-direction: column;
    padding-right: ${styles.spaces.small};
    padding-left: ${styles.spaces.small};
    padding-top: ${styles.spaces.small};
    padding-bottom: ${styles.spaces.small};
  }
  @media ${styles.breakpoints.mobile} {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const Info = styled.div`
  color:  ${props => props.dark ? 'white' : `#000000a8`};
  font-family: ${styles.fontFamilies.system};
  font-weight: ${styles.fontWeights.semiBold};
  font-size: ${styles.fontSizes.xLarge};
  padding-top: ${styles.spaces.xxLarge};
  padding-bottom: ${styles.spaces.xLarge};
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  & p {
    max-width: 980px;
    margin: auto;
    color:  ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
    line-height: 1.4;
  }
  
  @media ${styles.breakpoints.tabletPortrait} {
    flex-basis : 50%;
    // margin: 0 auto;
    // padding-bottom: ${styles.spaces.small};
    // text-align: center;
    padding-left: ${styles.spaces.large};
    padding-right: ${styles.spaces.large};
    // background: red;
    & p {
      font-size: ${styles.fontSizes.large};
      
    }
  }
  @media ${styles.breakpoints.mobile} {
    padding-left: ${styles.spaces.medium};
    padding-right: ${styles.spaces.medium};
    padding-bottom: ${styles.spaces.large};
  }
`;

const ImageWrap = styled.div`

// @media ${styles.breakpoints.tabletPortrait} {
  
//   padding: ${styles.spaces.small} auto ${styles.spaces.small} auto;
  
//   & p {
//     font-size: ${styles.fontSizes.large};
//   }
}
`;

const Picture = styled.div`
  // flex-basis : 40%;
  // align-self: flex-end;
  margin-bottom: ${styles.spaces.xxLarge};
  padding-bottom: 0;
  max-width: 1200px;
  // padding-right: ${styles.spaces.medium};
  // padding-left: ${styles.spaces.medium};
  & img {
    max-height: 90vh;
    max-width: 100%;
    width: auto;
    margin: auto;
  }
  @media ${styles.breakpoints.tabletPortrait} {
    flex-basis : 50%;
    // margin: 0 auto;
    // padding-right: ${styles.spaces.large};
    // padding-left: ${styles.spaces.large};
    & img {
      max-height: 90vh;
      max-width: 100%;
      width: auto;
      margin: auto;
    }
  } 
  @media ${styles.breakpoints.mobile} {
    // padding-right: ${styles.spaces.medium};
    // padding-left: ${styles.spaces.medium};
    margin-bottom: ${styles.spaces.xLarge};
    & img {
      max-height: auto;
      max-width: 100%;
      width: auto;
      margin: auto;
    }
  }  
`;

const Title = styled.h2`
  font-family: ${styles.fontFamilies.AlteHass};
  font-size: ${styles.fontSizes.xxxLarge};
  font-weight: ${styles.fontWeights.bold};
  // margin-top: ${styles.spaces.medium};
  margin-bottom: ${styles.spaces.xxxLarge};
  color: ${styles.colors.white};
  line-height: 1.2;
  opacity:  ${props => props.dark ? '1' : `0.7`};
  & img {
    max-height: 160px;
  }
  & a {
    color:  ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  & a:hover {
    color:  ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  @media ${styles.breakpoints.tabletPortrait} {
    margin-bottom: ${styles.spaces.xxLarge};
    // font-size: ${styles.fontSizes.xLarge};
  }
  @media ${styles.breakpoints.mobile} {
    margin-bottom: ${styles.spaces.xLarge};
    font-size: ${styles.fontSizes.xLarge};
  }
`;

const Button = styled.a`
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0px 56px 0px 0px;
  margin-top: ${styles.spaces.xLarge};
  margin-bottom: ${styles.spaces.xLarge};
  border-radius: 30px;
  color:  ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  font-size: ${styles.fontSizes.large};
  // background: ${styles.colors.white};
  font-weight: ${styles.fontWeights.semiBold};
  // -webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,.3);
  // box-shadow: inset 0 -2px 0 rgba(0,0,0,.3);
  &:hover {
    // background: ${styles.colors.primary.base};
    color:  ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
    cursor: pointer;
  }
  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-size: ${styles.fontSizes.small};
    position: absolute;
    right: 25px;
    padding: 12px 1px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  &:hover:before {
    transform: translateX(4px);
  }
  @media ${styles.breakpoints.tabletPortrait} {
    font-size: ${styles.fontSizes.medium};
    &:before {
      font-size: ${styles.fontSizes.xSmall};
      padding: 6px 1px;
    }
  }
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.small};
    &:before {
      font-size: ${styles.fontSizes.xxSmall};
    }
  }
`;




class ProjectInfo extends React.Component {
  render() {
    return (
      <ProjectsList>
        <div className={this.props.brand}>
        <Container className="notch">
          <Info {...this.props}>
            <Title {...this.props}><Link to={'/work/' + this.props.url}><img className="brand"  src={this.props.logo} alt={this.props.project} /></Link></Title>
            <ImageWrap>
            <Link to={'/work/' + this.props.url}>
              <Picture>
                <img src={this.props.fluid} alt={this.props.project} className={this.props.sty}/>
              </Picture>
            </Link>
          </ImageWrap>
            {this.props.children}
            <p>
            <Button  {...this.props} href={'/work/' + this.props.url}>View project</Button>
            </p>
          </Info>
        </Container>
        </div>
      </ProjectsList>
    );
  }
}

ProjectInfo.propTypes = {
  title: PropTypes.string
};

export default ProjectInfo
